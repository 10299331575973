/* ##remfixer: 1080 */
.Weal {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.Weal .AppBlockNormal .text {
  width: 5.555556rem;
  margin-top: 0.37037rem;
}
.Weal .AppBlockNormal .app-title {
  margin-bottom: 0.166667rem;
}
.content {
  width: 100%;
  overflow: hidden;
}

/*# sourceMappingURL=WealList-vue.38dc4aa3.css.map*/